<template>

  <v-card>
    <v-card-text>
      <v-row>
        <v-col v-for="(data, i) in init.home" :key="i" cols="12" sm="4" class="d-flex align-center">
          <v-avatar size="70" :color="resolveStatisticsIconVariation(i).color" rounded class="elevation-1">
            <v-icon dark color="white" size="50">
              {{ resolveStatisticsIconVariation(i).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xl mb-0">
              {{ i }}
            </p>
            <h3 class="text-2xl font-weight-semibold">
              {{ data }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiFolderMultipleImage,
  mdiDotsVertical,
  mdiFileDocumentMultipleOutline,
  mdiAccountClock, mdiCalendarClock, mdiAccountStar, mdiAutorenew
} from '@mdi/js'

export default {
  setup() {
    const statisticsData = [
      {
        title: 'Sales',
        total: '245k',
      },
      {
        title: 'Customers',
        total: '12.5k',
      },
      {
        title: 'Product',
        total: '1.54k',
      },
      {
        title: 'Revenue',
        total: '$88k',
      },
    ]

    const resolveStatisticsIconVariation = data => {
      if (data === 'Users') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Posts') return { icon: mdiFolderMultipleImage, color: 'primary' }
      if (data === 'Categories') return { icon: mdiFileDocumentMultipleOutline, color: 'warning' }
      if (data === 'Upcoming Expire Users') return { icon: mdiAccountClock, color: 'primary' }
      if (data === 'Recently Expired Users') return { icon: mdiCalendarClock, color: 'success' }
      if (data === 'Paid User') return { icon: mdiAccountStar, color: 'warning' }
      if (data === 'Demo User') return { icon: mdiAutorenew, color: 'success' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiFolderMultipleImage,
        mdiAccountOutline,
        mdiFileDocumentMultipleOutline,
        mdiCurrencyUsd,
      },
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
}
</script>
